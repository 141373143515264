<template>
  <gf-base>
    <gf-card :title="title" :subtitle="subtitle">
      <template #toolbar>
        <gf-button type="secondary" @click="back">
          <template slot="icon">
            <span class="svg-icon svg-icon-sx">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"/>
                  <path d="M4.7071045,12.7071045 C4.3165802,13.0976288 3.68341522,13.0976288 3.29289093,12.7071045 C2.90236664,12.3165802 2.90236664,11.6834152 3.29289093,11.2928909 L9.29289093,5.29289093 C9.67146987,4.914312 10.2810563,4.90106637 10.6757223,5.26284357 L16.6757223,10.7628436 C17.0828413,11.136036 17.1103443,11.7686034 16.7371519,12.1757223 C16.3639594,12.5828413 15.7313921,12.6103443 15.3242731,12.2371519 L10.0300735,7.38413553 L4.7071045,12.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(10.000001, 8.999997) scale(-1, -1) rotate(90.000000) translate(-10.000001, -8.999997) "/>
                  <path d="M20,8 C20.5522847,8 21,8.44771525 21,9 C21,9.55228475 20.5522847,10 20,10 L13.5,10 C12.9477153,10 12.5,10.4477153 12.5,11 L12.5,21.0415946 C12.5,21.5938793 12.0522847,22.0415946 11.5,22.0415946 C10.9477153,22.0415946 10.5,21.5938793 10.5,21.0415946 L10.5,11 C10.5,9.34314575 11.8431458,8 13.5,8 L20,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.750000, 15.020797) scale(-1, 1) translate(-15.750000, -15.020797) "/>
                </g>
              </svg>
            </span>
          </template>
          Back
        </gf-button>
      </template>
      <gf-content overflow>
        <gf-form :width="650">
          <el-form size="medium" label-width="130px" :model="pack" ref="pack" :rules="packRule">
            <el-form-item>
              <gf-alert>
                <template #icon>
                  <span class="svg-icon svg-icon-primary svg-icon-md">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "/>
                        <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                      </g>
                    </svg>
                  </span>
                </template>
                Provide details of pack / bundle below.
              </gf-alert>
            </el-form-item>
            <el-form-item label="Pack Name" prop="name">
              <el-input v-model="pack.name"></el-input>
            </el-form-item>
            <el-form-item label="SKU">
              <el-input v-model="pack.sku" disabled></el-input>
            </el-form-item>
            <el-form-item label="Pack Size" prop="pack_size">
              <el-input v-model="pack.pack_size"></el-input>
            </el-form-item>
            <el-form-item v-if="pack.prices.length > 0" class="mb-2">
              <span class="font-weight-bold text-dark-75">Prices</span>
            </el-form-item>
            <el-form-item :label="priceLabel[index]" v-for="(price, index) in pack.prices" :key="price.id">
              <el-input v-model="price.value"><template slot="prepend">₱</template></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <gf-button type="primary" @click="save">Save Changes</gf-button>
          </template>
        </gf-form>
      </gf-content>
    </gf-card>
  </gf-base>
</template>

<script>
import ProductVariantService from '@/services/v1/ProductVariant'

export default {
  data () {
    return {
      //
      loading: false,
      priceLabel: ['Buy Price', 'Wholesale Price', 'Retail Price'],
      title: '',
      subtitle: '',
      mode: 'create',

      // main
      variant: {
        name: null,
        product: {
          name: null
        }
      },
      pack: {
        name: null,
        sku: null,
        pack_size: null,
        is_pack: true,
        prices: [
          {
            price_list_id: 1,
            value: 0
          },
          {
            price_list_id: 2,
            value: 0
          },
          {
            price_list_id: 3,
            value: 0
          }
        ]
      },

      // Rule
      packRule: {
        name: [
          { required: true, message: 'Pack name is require', trigger: 'blur' }
        ],
        pack_size: [
          { required: true, message: 'Pack Size is require', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    back () {
      this.$router.back()
    },
    save () {
      this.$refs.pack.validate()
        .then(async () => {
          try {
            this.loading = true
            const pack = this.$_.cloneDeep(this.pack)
            pack.variant_id = this.$route.params.vid

            this.$Sanitize(pack)

            delete pack.stocks
            delete pack.options
            delete pack.options_label

            if (this.mode === 'create') {
              const pvService = new ProductVariantService(this.$route.params.id)
              await pvService.create(pack)
              this.$message.success(`${this.pack.name} successfully created`)
            } else {
              const pvService = new ProductVariantService(this.$route.params.id, this.$route.params.pid)
              await pvService.update(pack)
              this.$message.success(`${this.pack.name} successfully updated`)
            }

            this.$router.push({ name: 'product-variant-pack', params: { id: this.$route.params.id, vid: this.$route.params.vid } })
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },

    // Service fetch
    async getVariant () {
      try {
        const pvService = new ProductVariantService(this.$route.params.id, this.$route.params.vid)
        const result = await pvService.get()
        this.variant = result.data
        this.pack.sku = this.variant.sku
      } catch (error) {
        this.$Error(error)
      }
    },
    async getPack () {
      try {
        this.loading = true
        const pvService = new ProductVariantService(this.$route.params.id, this.$route.params.pid)
        const response = await pvService.get()
        this.pack = response.data

        if (this.pack.prices.length === 0) {
          this.pack.prices = [
            {
              price_list_id: 1,
              value: 0
            },
            {
              price_list_id: 2,
              value: 0
            },
            {
              price_list_id: 3,
              value: 0
            }
          ]
        }
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.mode = this.$route.meta.mode
    await this.getVariant()

    if (this.mode === 'create') {
      this.title = `${this.variant.name} - New Pack`
      this.subtitle = 'Create New Pack'
      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Inventory', route: '' },
        { title: 'Products' },
        { title: this.variant.product.name },
        { title: this.variant.name },
        { title: 'New Pack / Bundle' }
      ])
    } else {
      await this.getPack()
      this.title = `${this.variant.name} - ${this.pack.name} (Pack of ${this.pack.pack_size})`
      this.subtitle = 'Modify Pack Information'
      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Inventory', route: '' },
        { title: 'Products' },
        { title: this.variant.product.name },
        { title: this.variant.name },
        { title: this.pack.name }
      ])
    }
  }
}
</script>
